
import { defineComponent } from 'vue'
import { mapStateTyped } from '../store'
import customImg from '@/components/customImg.vue'
import { momentFilter } from '@/utils'
import WidgetAdapter from '@/components/common/WidgetAdapter.vue'

export default defineComponent({
  name: 'SearchResults',
  components: {
    customImg,
    WidgetAdapter,
  },
  inject: ['rewriteProps', 'spaParent'],
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    results: {
      type: Array,
      default: () => [],
    },
    params: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      date: null,
      currentItem: {} as any,
      numberCurrentItem: 0,
      menu: null,
      timeMenu: null,
      time: null,
      resultName: '',
      imgError: false,
      footerProps: {
        showFirstLastPage: true,
        itemsPerPageOptions: [4],
        itemsPerPageText: '',
      },
      headers: [
        { text: 'Модель', sortable: true, width: '12vw', value: 'model' },
        { text: 'Тип ТС', sortable: true, width: '12vw', value: 'kind' },
        { text: 'Цвет', sortable: true, width: '8vw', value: 'color' },
        {
          text: 'Номер',
          sortable: true,
          width: '8vw',
          value: 'license_plate',
        },
        { text: 'Фото', sortable: false, value: 'image' },
      ],
      passageDialog: false,
      page: 1,
      photoOrigin: '',
      photoBaseUrl: '',
    }
  },
  computed: {
    ...mapStateTyped(['api', 'zonesList']),
    isZonesReportProvided(): Boolean {
      // @ts-ignore
      return this.spaParent?.areas?.find?.((e: any) => e.name === 'zonesReport')
    },
    zonesPredefinedParameters() {
      return () => {
        return {
          zonesFinderTemplate: {
            events: this.zonesList.map((item: any) => {
              return {
                datetime: item.datetime,
                image: this.getImageUrl(item.image),
                location: {
                  latitude: item.location.latitude,
                  longitude: item.location.longitude,
                },
                license_plate: item.license_plate,
                model: item.model,
                country: item.country,
                kind: item.kind,
                uuid: item.uuid,
                color: item.color,
              }
            }),
            zone: this.$props.params.zones.map(function (item: any) {
              return {
                search_start_datetime: item.search_start_datetime,
                search_end_datetime: item.search_end_datetime,
                radius: item.radius,
                latitude: item.center_latitude,
                longitude: item.center_longitude,
              }
            }),
          },
        }
      }
    },
    passagePredefinedParameters() {
      return () => {
        this.passageDialog = false
        return {
          passageFinderTemplate: {
            event: {
              license_plate: this.currentItem.license_plate,
              color: this.currentItem.color,
              country: this.currentItem.country,
              datetime: this.currentItem.datetime,
              image: this.getImageUrl(this.currentItem.photos[this.numberCurrentItem]?.src),
              kind: this.currentItem.kind,
              location: {
                latitude: this.currentItem.location.latitude,
                longitude: this.currentItem.location.longitude,
              },
              model: this.currentItem.model,
              uuid: this.currentItem.uuid,
            },
          },
        }
      }
    },
  },
  watch: {
    results() {
      this.imgError = false
    },
  },
  mounted() {
    // @ts-ignore
    this.photoOrigin = this.rewriteProps.photoOrigin
    // @ts-ignore
    this.photoBaseUrl = this.rewriteProps.photoBaseUrl
  },
  methods: {
    getCurrentItem(item: any) {
      this.currentItem = { ...item }
      this.passageDialog = true
      this.currentItem.photos = this.currentItem.photos.map((ph: any) => ({
        ...ph,
        datetime: momentFilter(ph.datetime),
      }))
    },
    getImageModal(src: string) {
      return `${this.photoOrigin}/${src}`
    },
    getImageUrl(imageName: string) {
      return `${this.photoBaseUrl}/${imageName}`
    },
    closeDialog() {
      this.passageDialog = false
      this.numberCurrentItem = 0
      this.currentItem = {}
    },
  },
})
