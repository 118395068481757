
import Vue from 'vue'
import L, { latLng } from 'leaflet'
import { LMap, LTileLayer, LMarker, LTooltip, LControlZoom, LIcon } from 'vue2-leaflet'
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import ZonePeriod from '@/components/zone-period.vue'
import MarkerCircle from '@/components/common/MarkerCircle.vue'
import '@/plugins/draw-circle'
import '@/plugins/edit-circle'

export default Vue.extend({
  name: 'Map',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    LControlZoom,
    LIcon,
    MarkerCircle,
    'v-marker-cluster': Vue2LeafletMarkerCluster,
  },
  inject: ['rewriteProps'],
  props: {
    cddList: {
      type: Array as () => CDDSource[],
      default: () => [],
    },
    drawZones: {
      type: Boolean,
      default: false,
    },
    zones: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    mapOptions: {
      zoomSnap: 0.5,
      zoomControl: false,
      drawControl: true,
    },
    map: null as any,
    zoneId: 1,
    circle: null as any,
  }),
  computed: {
    visibleSources(): CDDSource[] {
      return this.cddList.filter((k: CDDSource) => k.location)
    },
    injectedProps(): IWidgetProps {
      // @ts-ignore
      return this.rewriteProps
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.$refs.myMap) return
      this.map = (this.$refs.myMap as any).mapObject

      if (this.$props.drawZones) {
        this.map.on('draw:editresize draw:editmove layeradd', (e: any) => {
          this.map.on('click', this.handleMapClick)
        })
        this.$watch('zones', (newZones, prevZones) => {
          if (this.$props.zones.length) return

          prevZones.forEach((z: any) => {
            this.map.removeLayer(z.circle)
            z.period && z.period.$destroy()
          })
        })
      }
    })
  },
  methods: {
    getCoordinates(kdd: CDDSource) {
      return latLng(kdd.location?.latitude, kdd.location?.longitude)
    },
    handleMapClick(evt: any) {
      const circle = L?.circle([evt.latlng.lat, evt.latlng.lng], 800)
      this.map?.addLayer(circle)
      circle.editing.enable()
      circle
        ?.bindPopup('', {
          autoClose: false,
          closeOnClick: false,
          closeOnEscapeKey: false,
          closeButton: false,
          className: 'period-popup',
        })
        .openPopup()

      const period = new ZonePeriod().$mount()
      period.$props.tz = this.injectedProps.tz
      const element = circle.getPopup().getElement().querySelector('.leaflet-popup-content')

      const _self = this
      period.$on('removeZone', () => {
        const zoneIndex = _self.$props.zones.findIndex(
          (item: any) => item.period === period && item.circle === circle,
        )
        const indexArray = []
        this.zoneId = this.zoneId - 1

        for (let i = zoneIndex + 1; i < _self.$props.zones.length; i++) {
          // номера зон, которые нужно уменьшить на 1
          indexArray.push(i + 1)
        }

        for (let i = 0; i < indexArray.length; i++) {
          const newId = indexArray[i] - 1

          const item = document.getElementsByClassName(`title${indexArray[i]}`)[0]

          if (newId > 0 && item) {
            // удаляем старый заголовок с номером, добавляем новый
            item.innerHTML = `Зона ${newId}`
            item.classList.remove(`title${indexArray[i]}`)
            item.classList.add(`title${newId}`)
          }
        }

        if (zoneIndex > -1) _self.$props.zones.splice(zoneIndex, 1)
        _self.map.removeLayer(circle)
        period.$destroy()

        indexArray.splice(0, indexArray.length)
      })

      element.appendChild(period.$el)

      if (!this.$props.zones.length) {
        this.zoneId = 1
      }
      const idx = this.zoneId++
      const titleBlock = document.getElementsByClassName('titleBlock')[idx - 1]
      // динамически добавляем заголовок с номером зоны
      const h3 = titleBlock.querySelector('.h')
      if (titleBlock && h3) {
        h3.className = `title${idx}`
        h3.innerHTML = `Зона ${idx}`
      }
      this.$props.zones.push({ circle, period })
      this.map.off('click', this.handleMapClick)
    },
  },
})
