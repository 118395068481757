/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
import { mapState, mapMutations, mapActions } from 'vuex'
import { FinderAPI } from './api'
import { mapActionItems } from './utils'

const initialState = () =>
({
  api: {} as FinderAPI,
  isDarkTheme: false as boolean,
  isSourceListLoading: false as boolean,
  sourceList: [] as CDDSource[],
  zonesList: [] as IZoneTraffic[],
  isZonesListLoading: false as boolean,
  lastRequestResult: undefined as {
    text: string;
    color: 'success' | 'info' | 'error' | 'warning';
  } | undefined
})

type State = ReturnType<typeof initialState>

const mutations = {
  setValue<T extends keyof State>(state: State, [key, value]: [T, State[T]]) {
    state[key] = value
  },
  reset(state: State) {
    const s = initialState()
    Object.keys(s).forEach((key) => {
      // @ts-ignore
      state[key] = s[key]
    })
  }
}

type Mutations = typeof mutations

type ActionArguments = {
  commit: <K extends keyof Mutations>(
    method: keyof Mutations,
    payload: Parameters<Mutations[K]>[1],
  ) => void
  state: State
  dispatch: <T extends keyof Actions>(
    action: T,
    // @ts-ignore
    payload?: Parameters<Actions[T]>[1],
  ) => PromiseLike<unknown> | void
}

const actions = {
  async loadSourceList({ state, commit }: ActionArguments) {
    try {
      commit('setValue', ['isSourceListLoading', true])
      const response = await state.api?.cdd?.getSourceList?.()
      if (response && 'error' in response) {
        console.log('error')
      } else {
        commit('setValue', ['sourceList', response])
      }
    } finally {
      commit('setValue', ['isSourceListLoading', false])
    }
  },
  async loadZonesList({ state, commit }:ActionArguments, params: IZonesTrafficRequest) {
    commit('setValue', ['isZonesListLoading', true])
    try {
        const response = await state.api?.traffic.getByZones(params)
        if (response && !response.length) {
            commit('setValue', ['lastRequestResult', {
                text: 'Ничего не найдено',
                color: 'info',
            }])
            return
        }

        if (response && response.length >= 10000) {
            commit('setValue', ['lastRequestResult', {
                text: 'Показаны не все результаты, уточните критерии поиска!',
                color: 'info',
            }])
        }

        const result = mapActionItems(response)
        commit('setValue', ['zonesList', result])
    } catch (error) {
      commit('setValue', ['lastRequestResult', {
            text: 'Ошибка при отправке запроса!',
            color: 'warning',
        }])
    } finally {
      commit('setValue', ['isZonesListLoading', false])
    }
  },
}

type Actions = typeof actions

export const mapStateTyped = <
  T extends keyof State,
  G extends { [Key in T]: () => State[Key] },
>(
  keys: T[],
): G => {
  return { ...mapState(keys) } as G
}

export const mapMutationsTyped = <
  T extends keyof Mutations,
  G extends {
    [K in T]: (I?: Parameters<Mutations[K]>[1]) => ReturnType<Mutations[K]>
  },
>(
  keys: T[],
): G => {
  return mapMutations(keys) as G
}

export const mapActionsTyped = <
  T extends keyof Actions,
  G extends {
    // @ts-ignore
    [Key in T]: (I?: Parameters<Actions[Key]>[1]) => ReturnType<Actions[Key]>
  },
>(
  keys: T[],
): G => {
  return mapActions(keys) as G
}

export default {
  state: initialState(),
  mutations,
  actions,
}
