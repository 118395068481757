import moment from 'moment'

export function momentFilter(date: Date | string | number): string {
  const utcTemp = moment.utc(date).toDate()
  return moment(utcTemp).local().format('DD.MM.YYYY HH:mm:ss')
}

export function changeArrayZonesList(array: any) {
  const numberMap: { [key: string]: any } = {}

  for (let index = 0; index < array.length; index++) {
    const element = array[index]
    const mapKey = `${element.license_plate}-${element.zone}`
    const elementInMap = mapKey in numberMap

    if (!elementInMap) {
      numberMap[mapKey] = {
        datetime: element.datetime,
        image: element.image,
        location: element.location,
        license_plate: element.license_plate,
        model: element.model,
        country: element.country,
        kind: element.kind,
        uuid: element.uuid,
        color: element.color,
        size: 1,
        photos: [
          {
            src: element.image,
            datetime: element.datetime,
            uuid: element.uuid,
          },
        ],
      }
    } else {
      const existed = numberMap[mapKey]
      existed.photos.push({
        src: element.image,
        datetime: element.datetime,
        uuid: element.uuid,
      })
      existed.size++
    }
  }

  return Object.values(numberMap)
}

export function mapActionItems(mapItem: any, lp?: string) {
  return mapItem.map(
    (item: any) =>
      ({
        datetime: item.datetime,
        image: item.image,
        location: item.location,
        license_plate: lp || item.vehicle.license_plate,
        model: item.vehicle.model.name,
        country: item.vehicle.country.description,
        kind: item.vehicle.kind.description,
        uuid: item.uuid,
        color: item.vehicle.color.description,
      } as IZoneTraffic)
  )
}
